<template>
<!-- v-if="getLocationMember && getActiveLocation && !showUsersLoader" -->
    <div v-if="getLocationMember && getActiveLocation && !showUsersLoader">
        <div class="header">
            <div class="container-fluid">
                <!-- Body -->
                <div class="header-body">
                    <div class="row align-items-end mt-n1"  >
                        <div class="col-auto mb-n5">
                            <!-- Avatar -->
                            <div class="avatar avatar-xxl header-avatar-top">
                                <img
                                    v-if="getLocationMember.MediumImageUrl"
                                    :src="getLocationMember.MediumImageUrl"
                                    alt="..."
                                    class="
                                        avatar-img-profile
                                        rounded-circle
                                        border border-4 border-body
                                    "
                                />
                                <!-- avatar-title -->
                                <span
                                    style="display: block !important;  background-color: transparent !important;"
                                    class="avatar-img-profile
                                        rounded-circle
                                        border border-4 border-body
                                    "
                                    v-else
                                >
                                    <!-- class="mt-4" <i class="fi flaticon-user" style="margin-left: 6px !important;"></i> style="width: 32px; height: 32px; margin-left: 6px !important;" -->
                                    <img v-if="isMobile || (this.width<700)" class="mt-n4" :src="'/images/person-big_MyZesec_140dp.png'" style="width: 95px; height: 95px;"/>
                                    <img v-else :src="'/images/person-big_MyZesec_140dp.png'" style="width: 98px; height: 98px;"/>
                                </span>
                            </div>
                        </div>
                        <!-- ml-n1 -->
                        <div class="col mb-1 ml-md-n5">
                            <!-- Pretitle -->
                            <h6 class="header-pretitle">
                                {{ $t("users.profile") }}
                            </h6>

                            <!-- Title -->
                            <h1 class="header-title">
                                {{ userFName(getLocationMember.FirstName) }}
                                {{ userLName(getLocationMember.LastName) }}
                            </h1>
                        </div>
                        <!--  v-if="$can('manageUsers', getActiveLocation)" -->
                        <div
                            class="col col-md-auto mt-2 mt-md-0 mb-md-3"
                            v-if="getUserRolesFiltered.length == 0"
                        >
                            <b-dropdown
                                :text="$t('buttons.manage')"
                                right
                                variant="primary"
                                toggle-tag="a"
                                class="float-right"
                            >
                                <b-dropdown-item
                                    class="text-left text-truncate"
                                    @click="deleteUser(getLocationMember)"
                                    >{{ $t("buttons.remove_from_location") }}</b-dropdown-item
                                >
                                <b-dropdown-item
                                    class="text-left text-truncate"
                                    @click="createInvite(getLocationMember)"
                                    >{{ $t("staff.invite") }}</b-dropdown-item
                                >
                            </b-dropdown>
                        </div>
                    </div>
                    <!-- / .row -->
                    <div class="row align-items-center">
                        <div class="col">
                            <!-- Nav -->
                            <ul class="nav nav-tabs nav-overflow header-tabs">
                                <li class="nav-item">
                                    <a
                                        href="#"
                                        class="nav-link"
                                        @click.stop.prevent="tabIndex = 0"
                                        v-bind:class="{ active: tabIndex == 0 }"
                                        >{{ $t("users.profile") }}</a
                                    >
                                </li>
                                <li class="nav-item">
                                    <a
                                        href="#"
                                        class="nav-link"
                                        @click.stop.prevent="tabIndex = 1"
                                        v-bind:class="{ active: tabIndex == 1 }"
                                        >{{ $t("keys.keys") }}</a
                                    >
                                </li>
                                <li class="nav-item">
                                   <!--  v-if="$can('viewDoors', getActiveLocation)" -->
                                    <a
                                        href="#"
                                        class="nav-link"
                                        @click.stop.prevent="tabIndex = 2"
                                        v-bind:class="{ active: tabIndex == 2 }"
                                        >{{ $t("doors.doors") }}</a
                                    >
                                </li>
                                <li class="nav-item">
                                    <a
                                        href="#"
                                        class="nav-link"
                                        @click.stop.prevent="tabIndex = 3"
                                        v-bind:class="{ active: tabIndex == 3 }"
                                        >{{ $t("staff.role") }}</a
                                    >
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- / .header-body -->
            </div>
        </div>
        <b-container fluid>
            <b-row>
                <b-col cols="12">
                    <b-card v-show="tabIndex == 0" transition="fade">
                        <template v-slot:title>
                            {{ $t("users.profile") }}
                        </template>
                        <div class="card-body">
                            <b-row>
                                <strong class="mx-2"
                                    >{{ $t("users.phone") }}:</strong
                                >
                                {{ getLocationMember.PhoneNumber }}
                            </b-row>
                            <b-row>
                                <strong class="mx-2">Email:</strong>
                                {{ getLocationMember.Email }}
                            </b-row>
                        </div>
                    </b-card>
                    <!-- <b-card
                        v-show="tabIndex == 1"
                        transition="fade"
                        body-class="px-0"
                    > -->
                    <div class="card" v-show="tabIndex == 1">
                    <div class="card-header">
                        <h4 class="card-header-title"></h4>
                        <form class="mr-3">
                            <Select2
                            v-model="perKeyPage"
                            :options="perPageOptions"
                            :settings="{
                                minimumResultsForSearch: -1,
                                containerCssClass:
                                'custom-select custom-select-sm form-control-flush',
                                dropdownCssClass: 'dropdown-menu dropdown-menu-sm',
                            }"
                            @change="paginatedKeyList"
                            />
                        </form>
                    </div>
                    <div class="card-header">
                        <form>
                            <div class="input-group input-group-flush input-group-merge">
                            <!-- Input -->

                            <b-form-input
                                :placeholder="$t('common.search')"
                                v-model="filterKey"
                                class="form-control form-control-prepended search"
                                type="search"
                            ></b-form-input>

                            <!-- Prepend -->
                            <div class="input-group-prepend">
                                <div class="input-group-text">
                                <span class="fe fe-search"></span>
                                </div>
                            </div>
                            </div>
                        </form>
                    </div>
                    <div class="card-body">
                        <b-pagination
                            v-model="currentKeyPage"
                            :total-rows="getLocationMemberKeys.length"
                            :per-page="perKeyPage"
                            aria-controls="my-table"
                            class="pb-2"
                        ></b-pagination>
                        <zs-list-transition :showLoader="showKeysLoader">
                            <template v-slot:content>
                                <!-- v-for="doorKey in getLocationMemberKeys"
                                v-for="doorKey in getLocationMemberKeysList" -->
                                <key-list-item
                                    v-for="doorKey in paginatedKeyList"
                                    v-bind:key="
                                        doorKey.KeyId +
                                            '-' +
                                            doorKey.UserId +
                                            '-' +
                                            doorKey.MemberId +
                                            '-' +
                                            doorKey.CalendarId || ''
                                    "
                                    :doorKey="doorKey"
                                    :showDeleting="true"
                                    class="flex-column align-items-start"
                                />
                            </template>
                            <template v-slot:empty>
                                <empty-list :title="$t('users.no_keys')" />
                            </template>
                        </zs-list-transition>
                        <b-pagination
                            v-model="currentKeyPage"
                            :total-rows="getLocationMemberKeys.length"
                            :per-page="perKeyPage"
                            aria-controls="my-table"
                            class="pb-2"
                        ></b-pagination>
                        </div>
                    </div>
                    <!-- </b-card> -->
                   <!--  v-if="$can('viewDoors', getActiveLocation)" -->
                    <!-- <b-card
                        v-show="tabIndex == 2"
                        transition="fade"
                        body-class="px-0"
                    > -->
                    <div class="card" v-show="tabIndex == 2">
                    <div class="card-header">
                        <h4 class="card-header-title"></h4>
                        <form class="mr-3">
                            <Select2
                            v-model="perDevicePage"
                            :options="perPageOptions"
                            :settings="{
                                minimumResultsForSearch: -1,
                                containerCssClass:
                                'custom-select custom-select-sm form-control-flush',
                                dropdownCssClass: 'dropdown-menu dropdown-menu-sm',
                            }"
                            @change="paginatedDeviceList"
                            />
                        </form>
                    </div>
                    <div class="card-header">
                        <form>
                            <div class="input-group input-group-flush input-group-merge">
                            <!-- Input -->

                            <b-form-input
                                :placeholder="$t('common.search')"
                                v-model="filterDevice"
                                class="form-control form-control-prepended search"
                                type="search"
                            ></b-form-input>

                            <!-- Prepend -->
                            <div class="input-group-prepend">
                                <div class="input-group-text">
                                <span class="fe fe-search"></span>
                                </div>
                            </div>
                            </div>
                        </form>
                    </div>
                    <div class="card-body">
                        <b-pagination
                            v-model="currentDevicePage"
                            :total-rows="getMemberLocationDevicesAdmin.length"
                            :per-page="perDevicePage"
                            aria-controls="my-table"
                            class="pb-2"
                        ></b-pagination>
                        <zs-list-transition :showLoader="showDoorsLoader">
                            <template v-slot:content>
                               <!--  v-for="device in getLocationMemberDevices"
                               v-for="device in getLocationMemberDevicesList" -->
                                <location-door-list-item
                                    v-for="device in paginatedDeviceList"
                                    v-bind:key="device.Device.Id"
                                    :device="device"
                                    class="flex-column align-items-start"
                                />
                            </template>
                            <template v-slot:empty>
                                <empty-list :title="$t('users.no_doors')" />
                            </template>
                        </zs-list-transition>
                        <b-pagination
                            v-model="currentDevicePage"
                            :total-rows="getMemberLocationDevicesAdmin.length"
                            :per-page="perDevicePage"
                            aria-controls="my-table"
                            class="pb-2"
                        ></b-pagination>
                    </div>
                    </div>
                    <!-- </b-card> -->
                    <!-- <b-card
                        v-show="tabIndex == 3"
                        transition="fade"
                        body-class="px-0"
                    > -->
                    <div class="card" v-show="tabIndex == 3">
                    <div class="card-header">
                        <h4 class="card-header-title"></h4>
                        <form class="mr-3">
                            <Select2
                            v-model="perRolePage"
                            :options="perPageOptions"
                            :settings="{
                                minimumResultsForSearch: -1,
                                containerCssClass:
                                'custom-select custom-select-sm form-control-flush',
                                dropdownCssClass: 'dropdown-menu dropdown-menu-sm',
                            }"
                            @change="paginatedRoleList"
                            />
                        </form>
                    </div>
                    <!-- <div class="card-header">
                        <form>
                            <div class="input-group input-group-flush input-group-merge">

                            <b-form-input
                                :placeholder="$t('common.search')"
                                v-model="filterRole"
                                class="form-control form-control-prepended search"
                                type="search"
                            ></b-form-input>

                            <div class="input-group-prepend">
                                <div class="input-group-text">
                                <span class="fe fe-search"></span>
                                </div>
                            </div>
                            </div>
                        </form>
                    </div> -->
                    <div class="card-body">
                        <b-pagination
                            v-model="currentRolePage"
                            :total-rows="getUserRolesFiltered.length"
                            :per-page="perRolePage"
                            aria-controls="my-table"
                            class="pb-2"
                        ></b-pagination>
                        <zs-list-transition :showLoader="showRolesLoader">
                            <template v-slot:content>
                               <!--  v-for="(role, index) in getUserRolesFiltered" -->
                                <user-role-item-setting
                                    v-for="(role, index) in paginatedRoleList"
                                    :role="role"
                                    v-bind:key="index"
                                />
                            </template>
                            <template v-slot:empty>
                                <empty-list :title="$t('staff.no_roles')" />
                            </template>
                        </zs-list-transition>
                        <b-pagination
                            v-model="currentRolePage"
                            :total-rows="getUserRolesFiltered.length"
                            :per-page="perRolePage"
                            aria-controls="my-table"
                            class="pb-2"
                        ></b-pagination>
                        </div>
                        </div>
                    <!-- </b-card> -->
                </b-col>
            </b-row>
        </b-container>

        <zs-delete-key-user-member-modal />
        <zs-delete-company-user-modal />
        <zs-delete-location-user-modal />
        <zs-company-invite-modal
            v-if="getActiveCompany"
            :company="getActiveCompany"
        /><zs-company-rerole-modal
            v-if="getActiveCompany"
            :company="getActiveCompany"
        />
        <zs-location-invite-modal
            :companyId="$route.params.companyId"
            :locationId="$route.params.locationId"
        />
        <zs-change-location-role-modal
            :companyId="$route.params.companyId"
            :locationId="$route.params.locationId"
        />
    </div>


    <!-- For trial -->
<div v-else-if="getLocationMember && !getActiveLocation && !showUsersLoader">
        <div class="header">
            <div class="container-fluid">
                <!-- Body -->
                <div class="header-body">
                    <div class="row align-items-end mt-n1"  >
                        <div class="col-auto mb-n5">
                            <!-- Avatar -->
                            <div class="avatar avatar-xxl header-avatar-top">
                                <img
                                    v-if="getLocationMember.MediumImageUrl"
                                    :src="getLocationMember.MediumImageUrl"
                                    alt="..."
                                    class="
                                        avatar-img-profile
                                        rounded-circle
                                        border border-4 border-body
                                    "
                                />
                                <span
                                    style="display: block !important;  background-color: transparent !important;"
                                    class="
                                        avatar-img-profile
                                        rounded-circle
                                        border border-4 border-body
                                    "
                                    v-else
                                >
                                    <!-- class="mt-4" <i class="fi flaticon-user" style="margin-left: 6px !important;"></i> style="width: 32px; height: 32px; margin-left: 6px !important;" -->
                                    <!-- <img :src="'/images/person-big_MyZesec_140dp.png'" style="width: 98px; height: 98px;"/> -->
                                    <img v-if="isMobile || (this.width<700)" class="mt-n4" :src="'/images/person-big_MyZesec_140dp.png'" style="width: 95px; height: 95px;"/>
                                    <img v-else :src="'/images/person-big_MyZesec_140dp.png'" style="width: 98px; height: 98px;"/>
                                </span>
                            </div>
                        </div>
                        <!-- ml-n1 -->
                        <div class="col mb-1 ml-md-n5">
                            <!-- Pretitle -->
                            <h6 class="header-pretitle">
                                {{ $t("users.profile") }}
                            </h6>

                            <!-- Title -->
                            <h1 class="header-title">
                                {{ userFName(getLocationMember.FirstName) }}
                                {{ userLName(getLocationMember.LastName) }}
                            </h1>
                        </div>
                        <!--  v-if="$can('manageUsers', getActiveLocation)" -->
                        <div
                            class="col col-md-auto mt-2 mt-md-0 mb-md-3"
                            v-if="getUserRolesFiltered.length == 0"
                        >
                            <b-dropdown
                                :text="$t('buttons.manage')"
                                right
                                variant="primary"
                                toggle-tag="a"
                                class="float-right"
                            >
                                <b-dropdown-item
                                    class="text-left text-truncate"
                                    @click="deleteUser(getLocationMember)"
                                    >{{ $t("buttons.remove_from_location") }}</b-dropdown-item
                                >
                                <b-dropdown-item
                                    class="text-left text-truncate"
                                    @click="createInvite"
                                    >{{ $t("staff.invite") }}</b-dropdown-item
                                >
                            </b-dropdown>
                        </div>
                    </div>
                    <!-- / .row -->
                    <div class="row align-items-center">
                        <div class="col">
                            <!-- Nav -->
                            <ul class="nav nav-tabs nav-overflow header-tabs">
                                <li class="nav-item">
                                    <a
                                        href="#"
                                        class="nav-link"
                                        @click.stop.prevent="tabIndex = 0"
                                        v-bind:class="{ active: tabIndex == 0 }"
                                        >{{ $t("users.profile") }}</a
                                    >
                                </li>
                                <li class="nav-item">
                                    <a
                                        href="#"
                                        class="nav-link"
                                        @click.stop.prevent="tabIndex = 1"
                                        v-bind:class="{ active: tabIndex == 1 }"
                                        >{{ $t("keys.keys") }}</a
                                    >
                                </li>
                                <li class="nav-item">
                                    <!-- v-if="$can('viewDoors', getActiveLocation)" -->
                                    <a
                                        href="#"
                                        class="nav-link"
                                        @click.stop.prevent="tabIndex = 2"
                                        v-bind:class="{ active: tabIndex == 2 }"
                                        >{{ $t("doors.doors") }}</a
                                    >
                                </li>
                                <li class="nav-item">
                                    <a
                                        href="#"
                                        class="nav-link"
                                        @click.stop.prevent="tabIndex = 3"
                                        v-bind:class="{ active: tabIndex == 3 }"
                                        >{{ $t("staff.role") }}</a
                                    >
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- / .header-body -->
            </div>
        </div>
        <b-container fluid>
            <b-row>
                <b-col cols="12">
                    <b-card v-show="tabIndex == 0" transition="fade">
                        <template v-slot:title>
                            {{ $t("users.profile") }}
                        </template>
                        <div class="card-body">
                            <b-row>
                                <strong class="mx-2"
                                    >{{ $t("users.phone") }}:</strong
                                >
                                {{ getLocationMember.PhoneNumber }}
                            </b-row>
                            <b-row>
                                <strong class="mx-2">Email:</strong>
                                {{ getLocationMember.Email }}
                            </b-row>
                        </div>
                    </b-card>
                    <!-- <b-card
                        v-show="tabIndex == 1"
                        transition="fade"
                        body-class="px-0"
                    > -->
                    <div class="card" v-show="tabIndex == 1">
                    <div class="card-header">
                        <h4 class="card-header-title"></h4>
                        <form class="mr-3">
                            <Select2
                            v-model="perKeyPage"
                            :options="perPageOptions"
                            :settings="{
                                minimumResultsForSearch: -1,
                                containerCssClass:
                                'custom-select custom-select-sm form-control-flush',
                                dropdownCssClass: 'dropdown-menu dropdown-menu-sm',
                            }"
                            @change="paginatedKeyListAdmin"
                            />
                        </form>
                    </div>
                    <div class="card-header">
                        <form>
                            <div class="input-group input-group-flush input-group-merge">
                            <!-- Input -->

                            <b-form-input
                                :placeholder="$t('common.search')"
                                v-model="filterKeyAdmin"
                                class="form-control form-control-prepended search"
                                type="search"
                            ></b-form-input>

                            <!-- Prepend -->
                            <div class="input-group-prepend">
                                <div class="input-group-text">
                                <span class="fe fe-search"></span>
                                </div>
                            </div>
                            </div>
                        </form>
                    </div>
                    <div class="card-body">
                        <b-pagination
                            v-model="currentKeyPage"
                            :total-rows="getLocationMemberKeysAdmin.length"
                            :per-page="perKeyPage"
                            aria-controls="my-table"
                            class="pb-2"
                        ></b-pagination>
                        <zs-list-transition :showLoader="showKeysLoader">
                            <template v-slot:content>
                                <!-- v-for="doorKey in getLocationMemberKeysAdmin"
                                v-for="doorKey in getLocationMemberKeysAdminList" -->
                                <key-list-item
                                    v-for="doorKey in paginatedKeyListAdmin"
                                    v-bind:key="
                                        doorKey.KeyId +
                                            '-' +
                                            doorKey.UserId +
                                            '-' +
                                            doorKey.MemberId +
                                            '-' +
                                            doorKey.CalendarId || ''
                                    "
                                    :doorKey="doorKey"
                                    :showDeleting="true"
                                    class="flex-column align-items-start"
                                />
                            </template>
                            <template v-slot:empty>
                                <empty-list :title="$t('users.no_keys')" />
                            </template>
                        </zs-list-transition>
                        <b-pagination
                            v-model="currentKeyPage"
                            :total-rows="getLocationMemberKeysAdmin.length"
                            :per-page="perKeyPage"
                            aria-controls="my-table"
                            class="pb-2"
                        ></b-pagination>
                        </div>
                    </div>
                    <!-- </b-card> -->
                   <!--  v-if="$can('viewDoors', getActiveLocation)" -->
                    <!-- <b-card
                        v-show="tabIndex == 2"
                        transition="fade"
                        body-class="px-0"
                    > -->
                    <div class="card" v-show="tabIndex == 2">
                    <div class="card-header">
                        <h4 class="card-header-title"></h4>
                        <form class="mr-3">
                            <Select2
                            v-model="perDevicePage"
                            :options="perPageOptions"
                            :settings="{
                                minimumResultsForSearch: -1,
                                containerCssClass:
                                'custom-select custom-select-sm form-control-flush',
                                dropdownCssClass: 'dropdown-menu dropdown-menu-sm',
                            }"
                            @change="paginatedDeviceListAdmin"
                            />
                        </form>
                    </div>
                    <div class="card-header">
                        <form>
                            <div class="input-group input-group-flush input-group-merge">
                            <!-- Input -->

                            <b-form-input
                                :placeholder="$t('common.search')"
                                v-model="filterDeviceAdmin"
                                class="form-control form-control-prepended search"
                                type="search"
                            ></b-form-input>

                            <!-- Prepend -->
                            <div class="input-group-prepend">
                                <div class="input-group-text">
                                <span class="fe fe-search"></span>
                                </div>
                            </div>
                            </div>
                        </form>
                    </div>
                    <div class="card-body">
                        <b-pagination
                            v-model="currentDevicePage"
                            :total-rows="getMemberLocationDevicesAdmin.length"
                            :per-page="perDevicePage"
                            aria-controls="my-table"
                            class="pb-2"
                        ></b-pagination>
                        <zs-list-transition :showLoader="showDoorsLoader">
                            <template v-slot:content>
                                <!-- v-for="device in getMemberLocationDevicesAdmin"
                                v-for="device in getMemberLocationDevicesAdminList" -->
                                <location-door-list-item
                                    v-for="device in paginatedDeviceListAdmin"
                                    v-bind:key="device.Device.Id"
                                    :device="device"
                                    class="flex-column align-items-start"
                                />
                            </template>
                            <template v-slot:empty>
                                <empty-list :title="$t('users.no_doors')" />
                            </template>
                        </zs-list-transition>
                        <b-pagination
                            v-model="currentDevicePage"
                            :total-rows="getMemberLocationDevicesAdmin.length"
                            :per-page="perDevicePage"
                            aria-controls="my-table"
                            class="pb-2"
                        ></b-pagination>
                        </div>
                      </div>
                    <!-- </b-card> -->
                    <!-- <b-card
                        v-show="tabIndex == 3"
                        transition="fade"
                        body-class="px-0"
                    > -->
                    <div class="card" v-show="tabIndex == 3">
                    <div class="card-header">
                        <h4 class="card-header-title"></h4>
                        <form class="mr-3">
                            <Select2
                            v-model="perRolePage"
                            :options="perPageOptions"
                            :settings="{
                                minimumResultsForSearch: -1,
                                containerCssClass:
                                'custom-select custom-select-sm form-control-flush',
                                dropdownCssClass: 'dropdown-menu dropdown-menu-sm',
                            }"
                            @change="paginatedRoleList"
                            />
                        </form>
                    </div>
                    <!-- <div class="card-header">
                        <form>
                            <div class="input-group input-group-flush input-group-merge">

                            <b-form-input
                                :placeholder="$t('common.search')"
                                v-model="filterRole"
                                class="form-control form-control-prepended search"
                                type="search"
                            ></b-form-input>

                            <div class="input-group-prepend">
                                <div class="input-group-text">
                                <span class="fe fe-search"></span>
                                </div>
                            </div>
                            </div>
                        </form>
                    </div> -->
                    <div class="card-body">
                        <b-pagination
                            v-model="currentRolePage"
                            :total-rows="getUserRolesFiltered.length"
                            :per-page="perRolePage"
                            aria-controls="my-table"
                            class="pb-2"
                        ></b-pagination>
                        <zs-list-transition :showLoader="showRolesLoader">
                            <template v-slot:content>
                               <!--  v-for="(role, index) in getUserRolesFiltered" -->
                                <user-role-item-setting
                                    v-for="(role, index) in paginatedRoleList"
                                    :role="role"
                                    v-bind:key="index"
                                />
                            </template>
                            <template v-slot:empty>
                                <empty-list :title="$t('staff.no_roles')" />
                            </template>
                        </zs-list-transition>
                        <b-pagination
                            v-model="currentRolePage"
                            :total-rows="getUserRolesFiltered.length"
                            :per-page="perRolePage"
                            aria-controls="my-table"
                            class="pb-2"
                        ></b-pagination>
                        </div>
                        </div>
                    <!-- </b-card> -->
                </b-col>
            </b-row>
        </b-container>

        <zs-delete-key-user-modal />
        <zs-delete-company-user-modal />
        <zs-delete-location-user-modal />
        <zs-company-invite-modal
            v-if="getActiveCompany"
            :company="getActiveCompany"
        /><zs-company-rerole-modal
            v-if="getActiveCompany"
            :company="getActiveCompany"
        />
        <!-- <zs-location-invite-modal
            :companyId="$route.params.companyId"
            :locationId="$route.params.locationId"
        />
        <zs-change-location-role-modal
            :companyId="$route.params.companyId"
            :locationId="$route.params.locationId"
        /> -->
    </div>
    <div class="main-content" v-else-if="showUsersLoader">
                <!-- Body -->
                <div class="card">
                    <div
                            style="width: 100px;
                                height: 100px;
                                position: absolute;
                                top: 100px;
                                left: 0;
                                right: 0;
                                margin: auto;"><b-spinner :label="$t('common.loading')"></b-spinner></div>
    </div></div>
</template>

<script>
import { mapGetters } from "vuex";
import KeyListItem from "@/components/key/KeyListItem";
import LocationDoorListItem from "@/components/device/LocationDoorListItem";
import ListTransition from "@/components/common/ListTransition";
import DeleteLocationUserModal from "@/components/modals/DeleteLocationUser";
import DeleteKeyUserModal from "@/components/modals/DeleteKeyUser";
import DeleteKeyUserMemberModal from "@/components/modals/DeleteKeyUserMember";
import EmptyList from "@/components/common/EmptyList";
import ChangeLocationEmployeeRole from "@/components/modals/ChangeLocationEmployeeRole";
import InviteLocationEmployeeModal from "@/components/modals/InviteLocationEmployee";
import UserRoleItemSetting from "@/components/user/UserRoleItemSetting";
import InviteCompanyEmployeeModal from "@/components/modals/InviteCompanyEmployeeAccordion";
import ChangeCompanyEmployeeRole from "@/components/modals/ChangeCompanyEmployeeRole";
import DeleteUserModal from "@/components/modals/DeleteUser";
import activityMixin from "@/mixins/activityMixin";
import {
    MEMBER_GET,
    MEMBER_KEYS_GET,
    MEMBER_DEVICES_GET,
    MEMBER_ROLES_GET,
    MEMBER_KEYS_GET_ADMIN,
    MEMBER_GET_ADMIN,
    MEMBER_DEVICES_GET_ADMIN,
} from "@/store/types/members";

import { LOCATION_GET } from "@/store/types/locations";

import { M_LOCATION_USER_DELETE,
  M_COMPANY_LOCATION_INVITE_FORM,
} from "@/components/modals/types";

import { validationMixin } from "vuelidate";
import modalMixin from "@/mixins/modalMixin";
import { CHECK_ADMIN_USER } from "@/store/types/admin";
import { ROLE_KEY_PERMANENT, ROLE_KEY_TEMPORARY } from "@/acl/roles";
import Select2 from "@/components/common/Select2";

export default {
    mixins: [validationMixin, modalMixin, activityMixin],
    data() {
        return {
            tabIndex: 0,
            showUsersLoader: true,
            showDoorsLoader: true,
            showKeysLoader: true,
            showRolesLoader: true,
            status: false,
            isMobile: false,
            width: window.innerWidth,
            height: window.innerHeight,
            inviteStaffFlag: false,
            filterRole: "",
            filterKey: "",
            filterKeyAdmin: "",
            filterDevice: "",
            filterDeviceAdmin: "",
            perRolePage: 20,
            currentRolePage: 1,
            perDevicePage: 20,
            currentDevicePage: 1,
            perKeyPage: 20,
            currentKeyPage: 1,
            perPageOptions: [
                { id: 10, text: "10 " + this.$t("menu.per_page") },
                { id: 20, text: "20 " + this.$t("menu.per_page") },
                { id: 50, text: "50 " + this.$t("menu.per_page") },
            ],
        };
    },
    computed: {
        ...mapGetters([
            "getLocationMember",
            "getLocationMemberKeys",
            "getLocationMemberDevices",
            "getActiveLocation",
            "getLocationMemberRoles",
            "getUser",
            "getUserCompanies",
            "getActiveCompany",
            "getCompanyEmployees",
            "getSuperAdmin",
            "getLocationMemberKeysAdmin",
            "getMemberLocationDevicesAdmin",
        ]),
        getUserRolesFiltered() {
            return this.getLocationMemberRoles.filter((item)=>
                ![ROLE_KEY_PERMANENT, ROLE_KEY_TEMPORARY].includes(item.Name));
        },
        paginatedRoleList(){
            return this.getUserRolesFiltered.slice(
                (this.currentRolePage - 1) * this.perRolePage,
                this.currentRolePage * this.perRolePage
            );
        },
        getLocationMemberKeysList(){
            return this.getLocationMemberKeys
                .filter((item)=>item.Key.Name.toLowerCase().includes(this.filterKey.toLowerCase()))
                .map((item)=>{
                    return item
                })
        },
        paginatedKeyList(){
            return this.getLocationMemberKeysList.slice(
                (this.currentKeyPage - 1) * this.perKeyPage,
                this.currentKeyPage * this.perKeyPage
            );
        },
        getLocationMemberKeysAdminList(){
             return this.getLocationMemberKeysAdmin
                .filter((item)=>item.Key.Name.toLowerCase().includes(this.filterKeyAdmin.toLowerCase()))
                .map((item)=>{
                    return item
                })
        },
        paginatedKeyListAdmin(){
            return this.getLocationMemberKeysAdminList.slice(
                (this.currentKeyPage - 1) * this.perKeyPage,
                this.currentKeyPage * this.perKeyPage
            );
        },
        getLocationMemberDevicesList(){
            return this.getLocationMemberDevices
                .filter((item)=>item.Device.Name.toLowerCase().includes(this.filterDevice.toLowerCase()))
        },
        paginatedDeviceList(){
            return this.getLocationMemberDevicesList.slice(
                (this.currentDevicePage - 1) * this.perDevicePage,
                this.currentDevicePage * this.perDevicePage
            );
        },
        getMemberLocationDevicesAdminList(){
            return this.getMemberLocationDevicesAdmin
                .filter((item)=>item.Device.Name.toLowerCase().includes(this.filterDeviceAdmin.toLowerCase()))
        },
        paginatedDeviceListAdmin(){
            return this.getMemberLocationDevicesAdminList.slice(
                (this.currentDevicePage - 1) * this.perDevicePage,
                this.currentDevicePage * this.perDevicePage
            );
        },
    },
    created() {
        /* this.$store.dispatch(LOCATION_GET, this.$route.params.locationId);
        this.$store.dispatch(MEMBER_GET, {
            locationId: this.$route.params.locationId,
            userId: this.$route.params.userId,
        })
         .then(() => (this.showUsersLoader = false));
        this.$store
            .dispatch(MEMBER_ROLES_GET, {
                companyId: this.$route.params.companyId,
                userId: this.$route.params.userId,
            })
            .then(() => (this.showRolesLoader = false));
        this.$store
            .dispatch(MEMBER_KEYS_GET, {
                locationId: this.$route.params.locationId,
                userId: this.$route.params.userId,
            })
            .then(() => (this.showKeysLoader = false));
        this.$store
            .dispatch(MEMBER_DEVICES_GET, {
                locationId: this.$route.params.locationId,
                userId: this.$route.params.userId,
            })
            .then(() => (this.showDoorsLoader = false));  */
        // this.getAdmin();
        window.addEventListener("resize", this.onResize);
        window.addEventListener("onload", this.onLoad);
        this.status = this.$session.get("adminUser");

      if (this.status) {
        if (this.$route.params.locationId) {
          this.$store.dispatch(LOCATION_GET, this.$route.params.locationId);
        }
        this.$store.dispatch(MEMBER_GET, {
          userId: this.$route.params.userId
        })
          .then(() => (this.showUsersLoader = false));
        this.$store
          .dispatch(MEMBER_KEYS_GET_ADMIN, {
            userId: this.$route.params.userId
          })
          .then(() => (this.showKeysLoader = false));
        this.$store
          .dispatch(MEMBER_DEVICES_GET_ADMIN, {
            userId: this.$route.params.userId
          })
          .then(() => (this.showDoorsLoader = false));
        this.$store
          .dispatch(MEMBER_ROLES_GET, {
            companyId: this.$route.params.companyId,
            userId: this.$route.params.userId
          })
          .then(() => (this.showRolesLoader = false));
      } else {
        if (this.$route.params.locationId) {
          this.$store.dispatch(LOCATION_GET, this.$route.params.locationId);
          this.$store.dispatch(MEMBER_GET, {
            locationId: this.$route.params.locationId,
            userId: this.$route.params.userId
          })
            .then(() => (this.showUsersLoader = false));
          this.$store
            .dispatch(MEMBER_KEYS_GET, {
              locationId: this.$route.params.locationId,
              userId: this.$route.params.userId
            })
            .then(() => (this.showKeysLoader = false));
          this.$store
            .dispatch(MEMBER_DEVICES_GET, {
              locationId: this.$route.params.locationId,
              userId: this.$route.params.userId
            })
            .then(() => (this.showDoorsLoader = false));
        } else {
          this.$store.dispatch(MEMBER_GET, {
            userId: this.$route.params.userId
          })
            .then(() => (this.showUsersLoader = false));
          this.$store
            .dispatch(MEMBER_KEYS_GET_ADMIN, {
              userId: this.$route.params.userId
            })
            .then(() => (this.showKeysLoader = false));
          this.$store
            .dispatch(MEMBER_DEVICES_GET_ADMIN, {
              userId: this.$route.params.userId
            })
            .then(() => (this.showDoorsLoader = false));
        }
        this.$store
          .dispatch(MEMBER_ROLES_GET, {
            companyId: this.$route.params.companyId,
            userId: this.$route.params.userId
          })
          .then(() => (this.showRolesLoader = false));
      }
    },
    methods: {
        onResize(e) {
            this.width = window.innerWidth;
            this.height = window.innerHeight;
        },
        onLoad(evt){
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                this.isMobile = true
            } else if(typeof window.orientation !== 'undefined' || navigator.userAgent.indexOf('IEMobile') !== -1){
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        },
        getAdmin(){
            this.$store.dispatch(CHECK_ADMIN_USER).then((response) => {
                if (response.data.status) {
                    this.status = response.data.status
                    if(this.status){
                        this.$store.dispatch(MEMBER_GET, {
                            userId: this.$route.params.userId,
                        })
                        .then(() => (this.showUsersLoader = false));
                        this.$store
                        .dispatch(MEMBER_KEYS_GET_ADMIN, {
                            userId: this.$route.params.userId,
                        })
                        .then(() => (this.showKeysLoader = false));
                        this.$store
                            .dispatch(MEMBER_DEVICES_GET_ADMIN, {
                                userId: this.$route.params.userId,
                            })
                            .then(() => (this.showDoorsLoader = false));
                        this.$store
                            .dispatch(MEMBER_ROLES_GET, {
                                companyId: this.$route.params.companyId,
                                userId: this.$route.params.userId,
                            })
                            .then(() => (this.showRolesLoader = false));
                    }else{
                        this.$store.dispatch(LOCATION_GET, this.$route.params.locationId);
                        this.$store.dispatch(MEMBER_GET, {
                            locationId: this.$route.params.locationId,
                            userId: this.$route.params.userId,
                        })
                        .then(() => (this.showUsersLoader = false));
                        this.$store
                            .dispatch(MEMBER_ROLES_GET, {
                                companyId: this.$route.params.companyId,
                                userId: this.$route.params.userId,
                            })
                            .then(() => (this.showRolesLoader = false));
                        this.$store
                            .dispatch(MEMBER_KEYS_GET, {
                                locationId: this.$route.params.locationId,
                                userId: this.$route.params.userId,
                            })
                            .then(() => (this.showKeysLoader = false));
                        this.$store
                            .dispatch(MEMBER_DEVICES_GET, {
                                locationId: this.$route.params.locationId,
                                userId: this.$route.params.userId,
                            })
                            .then(() => (this.showDoorsLoader = false));
                        }
                }
            });
        },
        deleteUser(user) {
            if(!user.Location){
                user.Location = this.getActiveLocation
                user.User = this.getLocationMember
            }
            this.showModal({ user: user }, M_LOCATION_USER_DELETE);
        },
        createInvite(user) {
            let sinvite = null
            if (user && user.PhoneNumber) {
                sinvite = {
                    Name: (this.userFName(user.FirstName) + " " + this.userLName(user.LastName)).trim(),
                    PhoneNumber: user.PhoneNumber,
                }
            }
            this.showModal({ staffInvite: sinvite }, M_COMPANY_LOCATION_INVITE_FORM);
        },
        userFName(FirstName){
            if(FirstName === null || FirstName === "null" || FirstName === ""){
                FirstName = '';
            }
            return FirstName;
        },
        userLName(LastName){
            if(LastName === null  || LastName === "null" || LastName === ""){
                LastName = '';
            }
            return LastName;
        },
    },
    destroyed() {
        window.removeEventListener("resize", this.onResize);
        this.isMobile = false;
    },
    components: {
        "zs-list-transition": ListTransition,
        KeyListItem,
        EmptyList,
        LocationDoorListItem,
        "user-role-item-setting": UserRoleItemSetting,
        "zs-delete-location-user-modal": DeleteLocationUserModal,
        "zs-delete-key-user-modal": DeleteKeyUserModal,
        "zs-delete-key-user-member-modal": DeleteKeyUserMemberModal,
        "zs-company-invite-modal": InviteCompanyEmployeeModal,
        "zs-company-rerole-modal": ChangeCompanyEmployeeRole,
        "zs-delete-company-user-modal": DeleteUserModal,
        "zs-location-invite-modal": InviteLocationEmployeeModal,
        "zs-change-location-role-modal": ChangeLocationEmployeeRole,
        Select2,
    },
};
</script>
<style lang="scss" scoped>
// fade
@import "../components/common/PageTransition/styles/transitions/fade/fade";
</style>
